<!--运输轨迹-->
<template>
    <div class="transportLine" v-loading="loading">
        <baidu-map
            class="map"
            :center="mapCenter"
            :zoom="zoom"
            ak="7FR14186sYk3MqjP9ggWxuHx1AQkRzZX"
            @ready="handleReady"
        >
            <bm-driving
                :start="startPos"
                :end="endPos"
                @searchcomplete="handleSearchComplete"
                :panel="false"
                :auto-viewport="true"
            ></bm-driving>
            <bml-lushu
                @stop="reset"
                :path="path"
                :icon="icon"
                :play="play"
                :rotation="true"
            >
            </bml-lushu>
        </baidu-map>
    </div>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue';
import { BmlLushu, BmDriving } from 'vue-baidu-map';
export default {
    name: 'transport-line',
    components: {
        BaiduMap,
        BmlLushu,
        BmDriving,
    },
    props: {
        extr: {
            type: Object,
        },
    },
    data() {
        return {
            loading: false,
            zoom: 3,
            mapCenter: { lng: 116.404, lat: 39.915 },

            play: true,
            path: [],
            icon: {
                url: require('../../images/car.png'),
                size: { width: 45, height: 28 },
                opts: { anchor: { width: 22, height: 14 } },
            },
            startPos: {
                lng: 0,
                lat: 0,
            },
            endPos: {
                lng: 0,
                lat: 0,
            },
            plate_number: '',
            begin_time: '',
            end_time: '',
        };
    },
    created() {
        this.loading = true;
        this.plate_number = this.extr.plate_number;
        this.begin_time = this.extr.out_time || '';
        this.end_time = this.extr.arrival_time || '';
        this.getLinePos();
    },
    methods: {
        // 地图加载完毕
        handleReady({ map }) {
            // 开启鼠标滚动缩放地图
            map.enableScrollWheelZoom(true);
            // 设置中心点、缩放级别
            this.zoom = 13;
            this.getLinePos();
        },
        reset() {
            this.play = false;
        },
        handleSearchComplete(res) {
            if (this.path.length > 0) {
                this.path = res.getPlan(0).getRoute(0).getPath();
            }
        },

        // 获取轨迹地理位置坐标数据
        getLinePos() {
            const tenantId = this.$takeTokenParameters('TenantId');
            const queryStr = `?tenantId=${tenantId}&plate_number=${this.plate_number}&begin_time=${this.begin_time}&end_time=${this.end_time}`;
            this.$axios.$get('/interfaceApi/logistics/car_manager/get_coordinate_list_by_plate_number' + queryStr)
                .then((res = []) => {
                    res.forEach(item => {
                        const newPos = this.bd_encrypt(item.longitude, item.latitude);
                        item.lng = newPos.lng;
                        item.lat = newPos.lat;
                    });
                    if (res.length > 1) {
                        this.startPos = res[0];
                        this.endPos = res[res.length - 1];
                        this.mapCenter = {
                            lng: res[0].lng,
                            lat: res[0].lat,
                        };
                    }
                    this.path = res;
                    this.loading = false;

                });
        },

        // 高德坐标转百度（传入经度、纬度）
        bd_encrypt(gg_lng, gg_lat) {
            const X_PI = Math.PI * 3000.0 / 180.0;
            const x = gg_lng;
            const y = gg_lat;
            const z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI);
            const theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI);
            const bd_lng = z * Math.cos(theta) + 0.0065;
            const bd_lat = z * Math.sin(theta) + 0.006;
            return {
                lng: bd_lng,
                lat: bd_lat,
            };
        },
    },
};
</script>

<style lang="stylus" scoped>
.transportLine
    width 100%;
    height 100%;
    .map
        width 100%;
        height 100%;
</style>
